import { RoleInGroup } from '@wix/social-groups-api';

const ALLOWED_ROLES = [RoleInGroup.GROUP_ADMIN];
const onlyAllowedRoles = (role: any) => ALLOWED_ROLES.includes(role);

export function getAllowedRoles(roles: RoleInGroup[] = []): RoleInGroup[] {
  return roles
    .map((role) => {
      switch (role as any) {
        case 'ADMIN':
          return RoleInGroup.GROUP_ADMIN;
        default:
          return role;
      }
    })
    .filter(onlyAllowedRoles);
}
