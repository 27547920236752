import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { SpinnerIcon } from './SpinnerIcon';
import { st, classes } from './Spinner.st.css';

export interface SpinnerProps {
  offset?: 'NONE' | 'S' | 'M' | 'L';
  label?: string;
  bw?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  label,
  offset = 'NONE',
  bw = false,
}) => (
  <div className={st(classes.root, { offset, bw })}>
    <SpinnerIcon width={20} height={20} className={classes.spinner} />
    <Text className={classes.label} typography={TextTypography.runningText}>
      {label}
    </Text>
  </div>
);

Spinner.displayName = 'Spinner';
