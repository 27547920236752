import React from 'react';
import { classes, st } from './Card.st.css';
import { Block, BlockAlign, BlockFlow } from '../../Block';
import {
  LayoutType,
  TextAlignment,
} from '../../../settings/groups-list/settingsConstants';
import { stylesParams } from 'common/settings/groups-list/styles';
import { useStyles } from '@wix/tpa-settings/react';
import { useShadowStyles } from 'Groups/Widget/hooks/useShadowStyles';

export enum Order {
  RTL = 'RTL',
  LTR = 'LTR',
}

export type CardLayout = LayoutType | 'pending';
interface Props {
  layout: CardLayout;
  cover?: React.ReactNode;
  details: React.ReactNode;
  cta?: React.ReactNode;
  /**
   * gap - spacing between cover and details
   * any CSS value for the `column-gap` property
   */
  gap?: string;
  /**
   * order of the cover and details - one of Order.LRT (default left to right) / Order.RTL (right to left)
   */
  order?: Order;
  /**
   * alignment of text in the details
   */
  textAlignment?: TextAlignment;
  mobile?: boolean;
}

const CARD_DETAILS = 'card-details';
const CARD_COVER = 'card-cover';
const CARD_CTA = 'card-cta';

export const Card: React.FC<Props> = ({
  cover,
  details,
  order,
  gap,
  layout,
  cta,
  textAlignment,
  mobile,
}) => {
  const shadowStyles = useShadowStyles();
  const grid = layout === LayoutType.grid;
  const list = layout === LayoutType.list || layout === LayoutType.listWidget;
  const pending = layout === 'pending';
  let justifyItems;
  const columns = (list && !mobile) || pending;
  if (columns) {
    justifyItems = BlockAlign.start;
  }
  if (layout === LayoutType.sideBySide) {
    switch (textAlignment) {
      case TextAlignment.Right:
        justifyItems = BlockAlign.end;
        break;
      case TextAlignment.Center:
        justifyItems = BlockAlign.center;
        break;
      case TextAlignment.Left:
        justifyItems = BlockAlign.start;
        break;
    }
  }
  return (
    <Block
      className={st(classes.root, {
        order: !mobile && order!,
        layout,
        mobile: mobile!,
        showShadow: shadowStyles.show,
      })}
      stylesOverride={{ columnGap: gap }}
      align={BlockAlign.center}
      flow={grid || mobile ? BlockFlow.row : BlockFlow.col}
    >
      <div className={st(classes.cover)} data-hook={CARD_COVER}>
        {cover}
      </div>
      <div className={classes.details} data-hook={CARD_DETAILS}>
        <Block
          flow={columns ? BlockFlow.col : BlockFlow.row}
          justify={justifyItems}
          align={columns ? BlockAlign.center : BlockAlign.stretch}
          stylesOverride={{ gridTemplateColumns: 'auto min-content' }}
          className={classes.detailBlock}
        >
          {details}
          {cta ? (
            <div className={classes.cta} data-hook={CARD_CTA}>
              {cta}
            </div>
          ) : null}
        </Block>
      </div>
    </Block>
  );
};

Card.displayName = 'Card';
