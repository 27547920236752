import { ISettingsParams } from '../settings/groups-list/settings';
import { IStylesParams } from '../settings/groups-list/styles';
import { ISettingsParams as GroupSettingsParams } from '../../components/Group/Settings/settingsParams';

export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export enum COMPONENT_ID { // from dev center
  GROUP_LIST = 'groups',
  GROUP_PAGE = 'group-page',
  GROUP = 'group',
  GROUPS_AREA = 'groups_area',
}

// EditorX returns 'False' if formFactor is not 'Desktop'
// Editor returns 'Mobile' if formFactor is not 'Desktop'
export function isMobileByFormFactor(formFactor: string): boolean {
  return formFactor !== 'Desktop';
}

/**
 * checks if an object has object type not null
 * @param o
 */
export function isObject(o: any) {
  return o === Object(o);
}

export function isMobileSettings() {
  return window.Wix.Utils.getDeviceType() === 'mobile';
}

type SettingsKeys =
  | keyof ISettingsParams
  | keyof IStylesParams
  | keyof GroupSettingsParams;

export function transformSettingsKey<K extends SettingsKeys>(
  desktopKey: K,
  { isMobile }: { isMobile: boolean },
): K {
  const keyWithUpperFirst = desktopKey[0].toUpperCase() + desktopKey.slice(1);
  return isMobile ? (`mobile${keyWithUpperFirst}` as K) : desktopKey;
}

export function doNotTransformSettingsKey<K extends SettingsKeys>(
  originalKey: K,
): K {
  return originalKey;
}

export function hideBodyOverflow(isOpen: boolean, className: string) {
  if (typeof document === 'undefined') {
    return;
  }
  try {
    if (isOpen) {
      !document.body.classList.contains(className) &&
        document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  } catch (e) {}
}

export function isUndefinedOrTrue(value: unknown) {
  return value === undefined || !!value;
}
