import { StyleParamType, createStylesParams } from '@wix/tpa-settings';

import { stylesParams as commonStyles } from '../../common/settings/groups-list/styles';
import { DEFAULT_GRID_CARD_SPACING } from '../../common/settings/groups-list/settingsConstants';

export const WIDGET_MAX_SPACING = 100;

export type IStylesParams = {
  sideBySideCardSpacing: StyleParamType.Number;
};

const stylesParams = createStylesParams<IStylesParams>({
  sideBySideCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    type: StyleParamType.Number,
  },
});
export default { ...commonStyles, ...stylesParams };
