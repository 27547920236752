import React from 'react';
import { useTranslation, useErrorMonitor } from '@wix/yoshi-flow-editor';
import { BlackAndWhiteImageLoader } from 'common/components/BlackAndWhiteImageLoader/BlackAndWhiteImageLoader';

export interface GroupLogoUploadProps {
  uploadImage(image: File): void;
  className?: string;
}

export const GroupLogoUpload: React.FC<GroupLogoUploadProps> = (props) => {
  const { uploadImage, className } = props;
  const { t } = useTranslation();
  const errorMonitor = useErrorMonitor();

  return (
    <BlackAndWhiteImageLoader
      errorMonitor={errorMonitor}
      uploadImage={uploadImage}
      uploadImageLabel={t('groups-web.modal.create-group.image.upload')}
      changeImageLabel={t('groups-web.modal.create-group.image.change')}
      deleteImageLabel={t('groups-web.modal.create-group.image.delete')}
      loadingLabel={t('groups-web.loading')}
      className={className}
    />
  );
};

GroupLogoUpload.displayName = 'GroupLogoUpload';
