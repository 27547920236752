import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';

import { Modal, ModalProps } from '../Modal';
import { DATA_HOOKS } from './dataHooks';
import { Button } from '../Button';

export interface DialogProps extends ModalProps {
  title?: string;
  caption?: string;
  cancelLabel?: string;
  okLabel?: string;
  onOkClick?(): void;
  onCancelClick?(): void;
  buttons?: React.ReactNode;
  footer?: React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    caption,
    cancelLabel,
    okLabel,
    onCancelClick,
    onOkClick,
    buttons,
    children,
    footer,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} data-hook={props['data-hook']}>
      {title ? <Modal.Header title={title} subtitle={caption} /> : null}
      {children}
      <Modal.Buttons>
        {buttons || (
          <>
            <Button
              bw={true}
              onClick={onCancelClick || onClose}
              priority={ButtonPriority.secondary}
              data-hook={DATA_HOOKS.cancelButton}
            >
              {cancelLabel || 'Cancel'}
            </Button>
            <Button
              bw={true}
              onClick={onOkClick}
              priority={ButtonPriority.primary}
              data-hook={DATA_HOOKS.okButton}
            >
              {okLabel || 'OK'}
            </Button>
          </>
        )}
      </Modal.Buttons>
      {footer}
    </Modal>
  );
};

Dialog.displayName = 'Dialog';
