import React from 'react';
import { WidgetContext } from '../../context/widget/WidgetContext';
import { LayoutType } from '../../settings/groups-list/settingsConstants';
import { settingsParams } from '../../settings/groups-list/settings';
import { widgetSettingsParams } from '../../settings/groups-list/widgetSettings';
import { useSettings } from '@wix/tpa-settings/react';
import { WidgetType } from '../../context/widget/WidgetType';

export const useImageRatio = (groupListLayout?: LayoutType) => {
  const { type } = React.useContext(WidgetContext);
  const settings = useSettings();
  if (groupListLayout === LayoutType.sideBySide) {
    return undefined;
  }
  if (type === WidgetType.ListWidget) {
    return settings.get(widgetSettingsParams.imageRatio);
  }
  return settings.get(settingsParams.imageRatio);
};
