import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Dialog } from '../Dialog';
import { ModalProps } from '../Modal/Modal';

interface ProfilePrivacyDialogProps extends ModalProps {
  onChangeProfile(): void;
}

export const ProfilePrivacyDialog: React.FC<ProfilePrivacyDialogProps> = (
  props,
) => {
  const { onChangeProfile, ...rest } = props;
  const { t } = useTranslation();

  const title = t('groups-web.join.profile');
  const text = t('groups-web.join.profile.text');
  const cancel = t('groups-web.cancel');
  const change = t('groups-web.join.profile.change');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={change}
      cancelLabel={cancel}
      onOkClick={onChangeProfile}
      {...rest}
    />
  );
};

ProfilePrivacyDialog.displayName = 'ProfilePrivacyDialog';
