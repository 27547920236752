import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Dialog } from '../Dialog';
import { ModalProps } from '../Modal/Modal';
import { DATA_HOOKS } from './dataHooks';

interface PrivateProfileDisclaimerProps extends ModalProps {
  onConfirm(): void;
}

export const PrivateProfileDisclaimer: React.FC<
  PrivateProfileDisclaimerProps
> = (props) => {
  const { onConfirm, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      data-hook={DATA_HOOKS.root}
      title={t('groups-web.private-profile-disclaimer.title')}
      caption={t('groups-web.private-profile-disclaimer.subtitle')}
      okLabel={t('groups-web.private-profile-disclaimer.ok')}
      cancelLabel={t('groups-web.cancel')}
      onOkClick={onConfirm}
      {...rest}
    />
  );
};

PrivateProfileDisclaimer.displayName = 'ProfilePrivacyDialog';
