import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { MembersLabelWithCountDict } from '../../MembersLabel/MembersLabel';
import { CTA_BUTTON_PREFERENCES } from '../cta/GroupCardCTA';
import type { ButtonPriority } from 'wix-ui-tpa';
import { IGroup } from '../../../types/IGroup';
import { getKey } from 'common/components/PluralText';

export interface IGroupLabels {
  membersCount: any;
  groupTypeLabel: any;
  pendingMembers: any;
  ctaLabel: any;
  ctaPriority: ButtonPriority;
}

export const useGroupLabels = ({
  membersName,
  privacyLevel,
  memberCount,
  pendingMembersCount,
  relationship,
}: Partial<IGroup>) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const ctaPreferences = CTA_BUTTON_PREFERENCES[relationship!];
  const ctaLabel = t(ctaPreferences.buttonLabelKey);
  const ctaPriority = ctaPreferences.priority;
  const translationKey = membersName
    ? MembersLabelWithCountDict[membersName]
    : MembersLabelWithCountDict.Members;

  const membersCount = translationKey
    ? t(translationKey, {
        count: memberCount,
      })
    : `${memberCount} ${membersName}`;

  const groupTypeLabel = t(
    `groups-web.group-list.privacy.${privacyLevel!.toLowerCase()}`,
  );

  const pendingMembers = t(
    getKey(
      experiments,
      'groups-web.group-list.pending-members.count',
      pendingMembersCount,
    ),
    {
      count: pendingMembersCount,
    },
  );

  return {
    membersCount,
    groupTypeLabel,
    pendingMembers,
    ctaLabel,
    ctaPriority,
  } as IGroupLabels;
};
