import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from '../../../../common/settings/groups-list/styles';
import { vars } from '../vars.st.css';

export const useShadowStyles = () => {
  const styles = useStyles();

  const [domNode, setDomNode] = React.useState<HTMLElement | null>(null);
  const onRefChange = React.useCallback((node) => {
    setDomNode(node);
  }, []);

  const shadowStyles = React.useMemo(() => {
    const shadowDistance = styles.get(stylesParams.shadowDistance);
    const showShadow = styles.get(stylesParams.applyShadow);
    const shadowAngle = styles.get(stylesParams.shadowAngle);
    const shadowXOffset =
      shadowDistance * Math.sin((Math.PI * 2 * shadowAngle) / 360);
    const shadowYOffset =
      -shadowDistance * Math.cos((Math.PI * 2 * shadowAngle) / 360);

    return {
      show: showShadow,
      xOffset: shadowXOffset,
      yOffset: shadowYOffset,
    };
  }, [styles]);

  React.useEffect(setShadowStyles, [shadowStyles, domNode]);

  function setShadowStyles() {
    if (domNode) {
      domNode.style.setProperty(
        vars.shadowXOffset,
        `${shadowStyles.xOffset}px`,
      );
      domNode.style.setProperty(
        vars.shadowYOffset,
        `${shadowStyles.yOffset}px`,
      );
    }
  }

  return {
    ...shadowStyles,
    onRefChange,
  };
};
