import React from 'react';
import { ToggleSwitch as TPAToggleSwitch, ToggleSwitchProps } from 'wix-ui-tpa';

import { st, classes } from './BlackAndWhiteToggleSwitch.st.css';

export const BlackAndWhiteToggleSwitch: React.FC<ToggleSwitchProps> = (
  props,
) => (
  <TPAToggleSwitch
    {...props}
    className={st(classes.root, {}, props.className)}
  />
);

BlackAndWhiteToggleSwitch.displayName = 'BlackAndWhiteToggleSwitch';
