import React from 'react';
import { Toast, ToastSkin } from 'wix-ui-tpa';
import {
  AppToast as AppToastType,
  AppToastsProps,
  AppToastTypes,
} from '@wix/social-groups-api';

import { st, classes /* ... */ } from './AppToast.st.css';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { getKey } from 'common/components/PluralText';

const messagesKey: { [key in AppToastTypes]: string } = {
  [AppToastTypes.GROUP_CREATED]: 'groups-web.toast.group-created',
  [AppToastTypes.GROUP_CREATED_IN_PENDING_STATE]:
    'groups-web.toast.group-pending',
  [AppToastTypes.GROUP_REQUEST_TO_JOIN]:
    'groups-web.toast.group-request-to-join',
  [AppToastTypes.GROUP_LEFT]: 'groups-web.toast.left-group',
  [AppToastTypes.GROUP_JOINED]: 'groups-web.toast.group-joined',
  [AppToastTypes.GROUP_APPROVED]: 'groups-web.toast.group-approved',
  [AppToastTypes.GROUP_DECLINED]: 'groups-web.toast.group-declined',
  [AppToastTypes.GROUP_CANCELLED]: 'groups-web.toast.group-cancelled',
  [AppToastTypes.GROUP_DELETED]: 'groups-web.group.actions.delete.success',
  [AppToastTypes.MEMBERS_ADDED]: 'groups-web.toast.members-added',
  [AppToastTypes.GROUP_LOGO_UPDATED]: 'groups-web.toast.group-logo-updated',
  [AppToastTypes.MEMBERSHIP_QUESTIONS_SAVED]:
    'groups-web.toast.membership-questions-saved',
};

const TOAST_ACKNOWLEDGE_TIMEOUT = 5000;

interface AppToastProps {
  t: any; // TODO: [YO]
  toast: AppToastType;
  acknowledge: AppToastsProps['acknowledge'];
  className?: string;
}

export const AppToast: React.FC<AppToastProps> = ({
  toast,
  className,
  acknowledge,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  React.useEffect(() => {
    let acknowledgeTimer: number;
    const time = TOAST_ACKNOWLEDGE_TIMEOUT - (Date.now() - toast.at);
    if (time > 0) {
      acknowledgeTimer = window.setTimeout(closeToast, time);
    } else {
      closeToast();
    }
    return () => {
      window.clearTimeout(acknowledgeTimer);
    };
  }, []);

  const toastKey =
    toast.options?.count !== undefined
      ? getKey(experiments, messagesKey[toast.type], toast.options?.count)
      : messagesKey[toast.type];

  return (
    <Toast
      className={st(classes.root, {}, className)}
      skin={ToastSkin.success}
      shouldShowCloseButton
      onClose={closeToast}
    >
      {t(toastKey, toast.options)}
    </Toast>
  );

  function closeToast() {
    acknowledge(toast.id);
  }
};
