import React from 'react';
import classname from 'classnames';
import {
  Text,
  Input,
  Button,
  ButtonPriority,
  TPAComponentsConfig,
  Dialog,
  DialogProps,
} from 'wix-ui-tpa';
import { GroupPrivacyLevel } from '@wix/social-groups-api';

import { GroupTypesDropdown } from './GroupTypesDrowdown';
import { GroupVisibilityDropdown } from './GroupVisibilityDropdown';

import { DATA_HOOKS } from './dataHooks';
import { st, classes } from './CreateGroupModal.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { compose } from '../../../../../common/utils/compose';
import { withGroupsListContext } from '../../../contexts/withGroupsListContext';
import { withTPAConfig } from '../../../../../common/components/withTPAConfig';
import { CreateGroupAction } from '../../../../../common/types/groups-list/types';

import { GroupLogoUpload } from '../../GroupLogoUpload/GroupLogoUpload';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { IGroupsListContext } from '../../../../../common/context/groups-list/IGroupsListContext';

const SOCIAL_GROUP_NAME_MAX_LENGTH = 50;
const DEFAULT_GROUP_PRIVACY_LEVEL = GroupPrivacyLevel.PUBLIC;

export interface CreateGroupModalProps extends DialogProps, CreateGroupAction {
  withPendingGroupExplanation?: boolean; // TODO add
}

interface CreateGroupState {
  title: string;
  privacyLevel: GroupPrivacyLevel;
}

type Props = CreateGroupModalProps &
  WithTranslation &
  IGroupsListContext &
  TPAComponentsConfig;
export class CreateGroupModal extends React.Component<Props, CreateGroupState> {
  static displayName = 'CreateGroupModal';
  private readonly defaultState = {
    title: '',
    privacyLevel: DEFAULT_GROUP_PRIVACY_LEVEL,
  };
  private groupImage!: File;
  readonly state = this.defaultState;

  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (!nextProps.isOpen && this.props.isOpen) {
      this.setState(this.defaultState);
    }
  };

  handleOnSubmit = (e: any) => {
    e.preventDefault();
    const { title, privacyLevel } = this.state;
    if (this.isValidTitle() && !this.props.isGroupCreating) {
      this.props.createGroup({ title }, { privacyLevel }, this.groupImage);
    }
  };

  isValidTitle() {
    const { title } = this.state;
    return !!title;
  }

  setSocialGroupName = (e: any) => {
    this.setState({
      title: e.target.value,
    });
  };

  setSocialGroupType = (option: any) => {
    this.setState({ privacyLevel: option.id });
  };

  render() {
    const {
      t,
      onClose,
      isGroupCreating,
      isOpen,
      mobile,
      withPendingGroupExplanation,
      dashboardLink,
    } = this.props;
    const { title, privacyLevel } = this.state;

    const isDisabledCreateButton = !this.isValidTitle() || isGroupCreating;
    const groupName = 'groupName';

    const shouldShowGroupVisibility = privacyLevel !== GroupPrivacyLevel.PUBLIC;

    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        className={st(classes.root, {
          mobile,
        } as any)}
        contentClassName={classes.contentWrapper}
        childrenWrapperClassName={classes.dialogContent}
        forceBWTheme={true}
        wiredToSiteColors={false}
      >
        <form onSubmit={this.handleOnSubmit} data-hook={DATA_HOOKS.root}>
          <Text className={classes.title}>
            {t('groups-web.modals.create-group.title')}
          </Text>
          <GroupLogoUpload
            className={classes.logoUpload}
            uploadImage={this.setImage}
          />

          <div className={classes.nameControl}>
            <Text className={classes.label}>
              <label htmlFor={groupName}>
                {t('groups-web.modals.create-groups.groupName')}
              </label>
              <span className={classes.counter}>
                {title.length}/{SOCIAL_GROUP_NAME_MAX_LENGTH}
              </span>
            </Text>
            <Input
              className={classes.groupNameInput}
              data-hook={DATA_HOOKS.groupName}
              maxLength={SOCIAL_GROUP_NAME_MAX_LENGTH}
              placeholder={t(
                'groups-web.modals.create-groups.groupName.placeholder',
              )}
              onChange={this.setSocialGroupName}
              id={groupName}
            />
          </div>

          <div className={classes.privacyControl}>
            <Text className={classes.label}>
              <span>{t('groups-web.modals.create-groups.groupType')}</span>
            </Text>
            <div>
              <GroupTypesDropdown
                selectedType={privacyLevel}
                onSelect={this.setSocialGroupType}
              />
            </div>
            {withPendingGroupExplanation ? (
              <Text
                className={classname(
                  classes.label,
                  classes.pendingGroupExplanation,
                )}
              >
                {t('groups-web.modals.create-group.pending-group-explanation')}
              </Text>
            ) : null}
          </div>

          {shouldShowGroupVisibility && (
            <div className={classes.groupVisibility}>
              <Text className={classes.label}>
                <span>
                  {t('groups-web.modals.create-groups.groupVisibility')}
                </span>
              </Text>
              <div>
                <GroupVisibilityDropdown
                  selectedType={privacyLevel}
                  onSelect={this.setSocialGroupType}
                />
              </div>
              {!!dashboardLink && (
                <div className={classes.goToDashboard}>
                  <Text>
                    {t('groups-web.modals.create-groups.manageAccessFrom')}
                  </Text>
                  <Button
                    as="a"
                    className={classes.goToDashboardButton}
                    priority={ButtonPriority.secondary}
                    href={dashboardLink}
                  >
                    {t('groups-web.modals.create-groups.manageAccessDashboard')}
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className={classes.buttons}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.cancelButton}
                data-hook={DATA_HOOKS.cancelButton}
                priority={ButtonPriority.secondary}
                onClick={onClose}
              >
                {t('groups-web.btn.cancel')}
              </Button>
            </div>
            <Button
              className={classes.createButton}
              data-hook={DATA_HOOKS.createButton}
              priority={ButtonPriority.primary}
              type="submit"
              disabled={isDisabledCreateButton}
              prefixIcon={isGroupCreating ? <Loader /> : undefined}
            >
              {isGroupCreating
                ? t('groups-web.btn.creating')
                : t('groups-web.btn.create')}
            </Button>
          </div>
        </form>
      </Dialog>
    );
  }

  private readonly setImage = (image: File) => {
    this.groupImage = image;
  };
}

const enhance = compose(
  withGroupsListContext,
  withTPAConfig,
  withTranslation(),
);

export default enhance(
  CreateGroupModal,
) as React.ComponentType<CreateGroupModalProps>;
