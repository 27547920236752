import React from 'react';
import { Theme } from './Theme';
import { ButtonType } from 'common/settings/groups-list/settingsConstants';

export const ThemeContext = React.createContext<Theme>({
  buttonType: ButtonType.rectangle,
});

ThemeContext.displayName = 'ThemeContext';

export const ThemeProvider = ThemeContext.Provider;
