import React from 'react';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { default as CreateGroupModal } from '../modals/CreateGroupModal/CreateGroupModal';
import { UserExceptionBoundary } from '../../../../common/components/UserExceptionBoundary/UserExceptionBoundary';
import { AppToasts } from 'common/components/AppToats';
import { useGroupsList } from 'Groups/contexts/useGroupsList';
import { useAppToasts } from 'common/components/AppToats/useAppToasts';
import { useGroupsActions } from 'Groups/contexts/useGroupsActions';

export type GroupsSectionRenderProps = {
  openCreateGroupModal(): void;
};

export type GroupsSectionProps = {
  children(props: GroupsSectionRenderProps): React.ReactNode;
};

export const GroupsSection: React.FC<GroupsSectionProps> = (props) => {
  const [isCreateGroupModalOpened, setIsCreateGroupModalOpened] =
    React.useState<boolean>(false);

  const { updateProgress, isGroupCreating } = useGroupsList();
  const actions = useGroupsActions();
  const toasts = useAppToasts();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isGroupCreating && isCreateGroupModalOpened) {
      closeCreateGroupModal();
    }
  }, [isGroupCreating]);

  if (updateProgress === UpdateProgress.UPDATING) {
    return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
  }

  return (
    <section key="groups">
      <AppToasts t={t} {...toasts} />
      <CreateGroupModal
        isOpen={isCreateGroupModalOpened}
        createGroup={actions && actions.createGroup}
        onClose={closeCreateGroupModal}
      />
      <UserExceptionBoundary />
      {props.children({ openCreateGroupModal })}
    </section>
  );

  function closeCreateGroupModal() {
    setIsCreateGroupModalOpened(false);
  }

  function openCreateGroupModal() {
    setIsCreateGroupModalOpened(true);
  }
};
